import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import logo from '../components/img/logo-allo_tbib.svg';

import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'

class Doctor extends React.Component {
  
  constructor(props) {
    
    super(props);
    //console.log(props.match.params.category_slug);
    const userCity=localStorage.getItem('userCity');
    this.state = {
        data: [],
        per: 3,
        page: 1,
        userCity:userCity,
        doctor_slug:props.match.params.doctor_slug
      };

  }

  loadUser() {


    const {page, data } = this.state;
    const url = 'https://tbib.envose.com/api/get_doctor?city='+this.state.userCity+'&doctor_slug='+this.state.doctor_slug;
       fetch(url,{mode: 'cors'})
      .then(response => response.json())
      .then(json =>
       this.setState({
            data: json.data,
            scrolling: false
        })
   );
 }

 componentWillMount() {
    this.loadUser();
 }
  Doctor_details(){
    const items = this.state.data || [] 
    return(
      items.map(data => (
                                
        <Row  className="dr-list-item-single">

        <Col sm={4} xs={4} lg={4} >
        <Image src={data.profile_image_url}  />

        </Col>

        <Col  sm={8} xs={8} lg={8} className="dr-list-item-desc-single">
        <h3>{data.doctor_name}</h3>
        <p>{data.category_name_ar}</p>
        <p>{data.doctor_reg_no}</p>
        <p>{data.doctor_location}</p>
        <div className="contact-ele">
          <Button href={`tel:${data.doctor_phone}`} className="btn-call">مكالمة</Button>
          <Button href={`mailto:${data.doctor_email}`} className="btn-email">رسالة</Button>
          <Button href={`https://wa.me/${data.doctor_phone}`} className="btn-whatsapp">واتساب</Button>
        </div>
        </Col>  
        <Col  sm={12} xs={12} lg={12} className="dr-list-item-desc-single">
        <h2>حول</h2>
        <p>{data.doctor_description}</p>

        </Col>   
    </Row>

))

    )
  }  
  render() {
console.log(this.state);
  return (

   
   <div>
    <Navbar expand="lg" variant="light" bg="light" fixed="top">
    <Container>
      <Row>

            <Col className='text-right'>
            <Navbar.Brand href="#">
                <img src={logo} className="app-logo" />

            </Navbar.Brand>
            </Col>
            <Col>
                <Link to="/categories" className="back-link">عودة</Link>
            </Col>
      </Row>
    </Container>
    </Navbar>
  
  <div  className="page">
   

                    <div className="full-bg-category-title">
                    <h4>إختر فئتك</h4>
                    </div>
                
                    
                    <Container className="page-start">
                            {this.Doctor_details()}
                    </Container>

            </div>

            </div>
  );
  }

}


export default Doctor;
