import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import logo from '../components/img/logo-allo_tbib.svg';

import Navbar from 'react-bootstrap/Navbar';

class Categories extends React.Component {


  constructor(props) {
    
    super(props);
    //console.log(props.match.params.category_slug);
    const userCity=localStorage.getItem('userCity');
    this.state = {
        data: [],
        per: 3,
        page: 1,
        userCity:userCity,
        category_slug:props.match.params.category_slug,
        category_name_ar:'',
        num_rows:1,
      };



  }

  Get_categories() {


  
    const url = 'https://tbib.envose.com/api/get_category';
       fetch(url,{mode: 'cors'})
      .then(response => response.json())
      .then(json =>
       this.setState({
            categories: json.data,
            scrolling: false
        })
   );
  
  
      }

category_item_list(){

  const categories = this.state.categories || [] 
  
  if(categories && categories.length){

              return(

                categories.map(data => (

                      <Col sm={4} xs={4} lg={4} key={data.category_id}>
                      <Link to={`/category/${data.category_slug}`}>
                        <div  className="home-icons">
                          <img src={data.cat_image_url}/>
                          <h4>{data.category_name_ar}</h4>
                        </div>
                        </Link>
                      </Col>

                      ))
              )
  }
  else{

  }   
} 

componentWillMount() {
  this.Get_categories();

}

 render() {
  return (

  <div  className="page">
    <Navbar expand="lg" variant="light" bg="light" fixed="top">
    <Container>
      <Row>
            <Col className='text-right'>
            <Navbar.Brand href="#">
                <img src={logo} className="app-logo" />

            </Navbar.Brand>
            </Col>
            <Col>
                <Link to="/" className="back-link">تغيير المدينة</Link>
            </Col>
      </Row>
    </Container>
    </Navbar>
  

   

    <div className="full-bg-categories">
       <h4>إختر فئتك</h4>
    </div>
   
    
    <Container className="page-start">
            
          
                <Row>


           {this.category_item_list()}

            {/* <Col sm={4} xs={4} lg={4} >
            <Link to="/category/pediatrics">
              <div  className="home-icons">
                <img src={pediatrician}/>
                <h4>طبيب الأطفال</h4>
              </div>
              </Link>
            </Col>
            


            <Col sm={4} xs={4} lg={4} >
            <Link to="/category/gynecology">
              <div  className="home-icons">
                <img src={gynecology}/>
                <h4>أمراض النساء</h4>
              </div>
              </Link>
            </Col>

            </Row>
            <Row>
            <Col sm={4} xs={4} lg={4} >
            <Link to="/category/orthopedics">
              <div  className="home-icons">
                <img src={orthopedics}/>
                <h4>طب العظام</h4>
              </div>
              </Link>
            </Col>

            <Col sm={4} xs={4} lg={4} >
            <Link to="/category/skin-specialist">
              <div  className="home-icons">
                <img src={eye_specialist}/>
                <h4>أخصائي الجلد</h4>
              </div>
              </Link>
            </Col>

            <Col sm={4} xs={4} lg={4} >
            <Link to="/category/dentist">
              <div  className="home-icons">
                <img src={dentist}/>
                <h4>دكتورالاسنان</h4>
              </div>
              </Link>
            </Col>



            </Row>


            <Row>
            <Col sm={4} xs={4} lg={4} >
            <Link to="/category/general-medicine">
              <div  className="home-icons">
                <img src={eye_specialist}/>
                <h4>متخصص في العيون</h4>
              </div>
              </Link>
            </Col> */}

        

        



            </Row>

            



        </Container>
    
        


       


    {/* <Footer /> */}
    </div>
    
  );
}
}
export default Categories;
