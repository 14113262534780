import React from 'react';

import Header from '../components/Header'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import logo from '../components/img/logo-allo_tbib.svg';
import docimg from '../components/img/doc_png.png';
import Demo from  '../components/Demo';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'


class Home extends React.Component {
  
  constructor(props) {
    
    super(props);
    const userCity=localStorage.getItem('userCity');
    if(userCity){
      this.state = {
        cities:[],
        SelectCity: userCity
      };
    }
    else{
      this.state = {
        cities:[],
        SelectCity: 3
      };
      localStorage.setItem('userCity','3');
    }

    console.log(this.state.SelectCity)

    this.handleInputChange = this.handleInputChange.bind(this);

  }
  
  set_city(event){
  

    event.preventDefault();

  }

  Get_cities() {


  
    const url = 'https://tbib.envose.com/api/get_cities';
       fetch(url,{mode: 'cors'})
      .then(response => response.json())
      .then(json =>
       this.setState({
            cities: json.data,
            scrolling: false
        })
   );


      }

  componentWillMount() {
    this.Get_cities();
  }
          
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    localStorage.setItem('userCity',value);


  }  
  city_list(){

    const cities = this.state.cities || [] 
    
    if(cities && cities.length){

                return(

                  cities.map(data => (
                        <option value={data.city_id}>{data.city_name_ar}</option>
                        ))
                )
    }
    else{

    }   
  }
  render() {
     
  return (
      <div className="page">
  

        <Container className="page-start-home" dir="rtl">
          <div className="logo-home-wrapper">
            <img src={logo} className="app-logo-home" />
          </div>
          <div className="doc-img-home">
            <img src={docimg} className="" />

            <h3>#بقا_فدارك</h3>
          </div>       
          
        <form onSubmit={this.set_city}>
          <Form.Group controlId="city.SelectCity">
          <Form.Label dir="rtl">اختر مدينتك</Form.Label>
          <Form.Control name="SelectCity" value={this.state.SelectCity}  onChange={this.handleInputChange} as="select" custom>
            {this.city_list()}
          </Form.Control>
          </Form.Group>
          
          </form>
          <Link to="/categories"><Button className="btn-infi-app">ابحث عن طبيب</Button></Link>  

        <Row>
          <Col sm={12} className="dr-registration-link">

             <Link to="doctor-registration" >هل أنت طبيب؟ سجل معنا الآن و قم بإدارة حسابك</Link>
          </Col>
        </Row>
        <Row className="home-footer">
            <Col sm={12} className="text-center">
               <p><span>&hearts;</span> صنع في المغرب</p>
            </Col>
        </Row>
        </Container>
    
            

{/* 
    <Footer /> */}
    </div>
  );
  }

}


export default Home;
