import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import logo from '../components/img/logo-allo_tbib.svg';
import docimg from '../components/img/doc_png.png';

import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
class Doctor_registration extends React.Component {
  
  constructor(props) {
    
    super(props);
    //console.log(props.match.params.category_slug);
    const userCity=localStorage.getItem('userCity');
    this.state = {
        data: [],
        per: 3,
        page: 1,
        userCity:userCity,
        category_slug:props.match.params.category_slug,
        category_name_ar:'',
        num_rows:1,
        show_loading:false,
        hide_form:false
      };

      this.handleSubmit = this.handleSubmit.bind(this);


  }

  Register() {

    
    const {page, data } = this.state;
    const url = 'https://tbib.envose.com/api/get_doctors?city='+this.state.userCity+'&category_slug='+this.state.category_slug;
       fetch(url,{mode: 'cors'})
      .then(response => response.json())
      .then(json =>
       this.setState({
            data: json.data,
            category_name_ar:json.category_name_ar,
            num_rows:json.num_rows,
            scrolling: false,
            categories:[]
        })
   );
 }
 handleSubmit(event) {
  event.preventDefault();
  const form = event.target;
  const data = new FormData(form);
  this.setState({ show_loading: true}); 

  for (let name of data.keys()) {
    const input = form.elements[name];

   
      data.set(name, data.get(name));
    
  }
  
  fetch('https://tbib.envose.com/api/register_doctor', {
    method: 'POST',
    mode: 'cors',
    body: data,
  }).then(response => response.json())
  .then((json) => {
    if(json.status=='success'){
      this.setState({
        show_loading: false,
        hide_form:true
        })
    }
    else if(json.status=='already_registered'){

      alert('Already registered!')
      this.setState({
        show_loading: false
    
        })     
    }
    else if(json.status=='validation_error'){

      alert('Please fill all fields!')
      this.setState({
        show_loading: false
    
        })     
    }

  }
)
}

 Get_cities() {


  
  const url = 'https://tbib.envose.com/api/get_cities';
     fetch(url,{mode: 'cors'})
    .then(response => response.json())
    .then(json =>
     this.setState({
          cities: json.data,
          scrolling: false
      })
 );


    }

    Get_categories() {


  
      const url = 'https://tbib.envose.com/api/get_category';
         fetch(url,{mode: 'cors'})
        .then(response => response.json())
        .then(json =>
         this.setState({
              categories: json.data,
              scrolling: false
          })
     );
    
    
        }
componentWillMount() {
  this.Get_cities();
  this.Get_categories();

}
category_list(){

  const categories = this.state.categories || [] 
  
  if(categories && categories.length){

              return(

                categories.map(data => (
                      <option key={data.category_id} value={data.category_id}>{data.category_name_ar}</option>
                      ))
              )
  }
  else{

  }   
}      

city_list(){

  const cities = this.state.cities || [] 
  
  if(cities && cities.length){

              return(

                cities.map(data => (
                      <option value={data.city_id}>{data.city_name_ar}</option>
                      ))
              )
  }
  else{

  }   
}
  registration_form(){


    return(
        <div>

            <Form className="doctor_reg_form" onSubmit={this.handleSubmit}>

                <Form.Group controlId="Registration.Name">
                <Form.Label>الاسم بالكامل*</Form.Label>
                <Form.Control type="text" name="Name" placeholder="" />
                
                </Form.Group>

                <Form.Group controlId="Registration.Gender">
                <Form.Label dir="rtl">جنس*</Form.Label>
                <Form.Control name="Gender" value={this.state.Gender}  onChange={this.handleInputChange} as="select" custom>
                      <option value="1">الذكر</option>
                      <option value="2">أنثى</option>

                </Form.Control>
                </Form.Group>




                <Form.Group controlId="Registration.doctor_reg_no">
                <Form.Label>الرقم الاستدلالي*</Form.Label>
                <Form.Control type="text" name="doctor_reg_no" placeholder="" />
                
                </Form.Group>


                <Form.Group controlId="Registration.About">
                <Form.Label>حولك</Form.Label>
                <Form.Control name="About" as="textarea" rows="3" />
                
                </Form.Group>

                <Form.Group controlId="Registration.Category">
                <Form.Label dir="rtl">اختر مدينتك*</Form.Label>
                <Form.Control name="Category" value={this.state.Category}  onChange={this.handleInputChange} as="select" custom>
                  {this.category_list()}
                </Form.Control>
                </Form.Group>

                <Form.Group controlId="Registration.Phone">
                <Form.Label>هاتف*</Form.Label>
                <Form.Control type="text" name="Phone" placeholder="" />
                <Form.Text className="text-muted">
                
                </Form.Text>
                </Form.Group>

                <Form.Group controlId="Registration.Whatsapp">
                <Form.Label>واتساب*</Form.Label>
                <Form.Control type="text" name="Whatsapp" placeholder="" />
                <Form.Text className="text-muted">
                
                </Form.Text>
                </Form.Group>


                <Form.Group controlId="Registration.SelectCity">
                <Form.Label dir="rtl">اختر مدينتك*</Form.Label>
                <Form.Control name="SelectCity" value={this.state.SelectCity}  onChange={this.handleInputChange} as="select" custom>
                  {this.city_list()}
                </Form.Control>
                </Form.Group>



                
                <Form.Group controlId="Registration.Location">
                <Form.Label>موقعك*</Form.Label>
                <Form.Control type="text" name="Location" placeholder="" />

                </Form.Group>


                <Form.Group controlId="Registration.Email">
                <Form.Label>البريد الإلكتروني*</Form.Label>
                <Form.Control type="email" name="Email" placeholder="" className="emailinput" />

                </Form.Group>


                <Form.Group controlId="Registration.Password">
                <Form.Label>كلمه السر*</Form.Label>
                <Form.Control type="password" name="Registration.Password" placeholder="" />
                </Form.Group>
              

                <Button variant="primary" className="btn-infi-app-form" type="submit">
                {
                    this.state.show_loading  &&
                    <div><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/><span className="sr-only">Loading...</span></div>
                }
          تسجيل     
                </Button>
            </Form>

          </div>

    )
  }
  form_body(){

    if(!this.state.hide_form){
    return(

      <div>
        {this.registration_form()}
      </div>
    )
    }
    else{
      return(

        <div>

            <Alert variant='success' className="success-alert-cst">
            

           ! تم التسجيل بنجاح <div id="tick-mark"></div>
            </Alert>
          
         
        </div>
      )      
    }


  }
  render() {

  return (

     
  <div  className="page">
   
    <Navbar expand="lg" variant="light" bg="light" fixed="top">
    <Container>
      <Row>

            <Col className='text-right'>
            <Navbar.Brand href="#">
                <img src={logo} className="app-logo" />

            </Navbar.Brand>
            </Col>
            <Col>
                <Link to="/categories" className="back-link">عودة</Link>
            </Col>
      </Row>
    </Container>
    </Navbar>

          
                <div className="full-bg-category-title">
                <h4>تسجيل طبيب</h4>
                </div>

                
                <Container className="page-start">

                      {this.form_body()}

                </Container>

      </div>

  );
  }

}


export default Doctor_registration;
