import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import logo from '../components/img/logo-allo_tbib.svg';
import docimg from '../components/img/doc_png.png';

import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'

class Category extends React.Component {
  
  constructor(props) {
    
    super(props);
    //console.log(props.match.params.category_slug);
    const userCity=localStorage.getItem('userCity');
    this.state = {
        data: [],
        per: 3,
        page: 1,
        userCity:userCity,
        category_slug:props.match.params.category_slug,
        category_name_ar:'',
        num_rows:1,
      };

  }

  loadUser() {


    const {page, data } = this.state;
    const url = 'https://tbib.envose.com/api/get_doctors?city='+this.state.userCity+'&category_slug='+this.state.category_slug;
       fetch(url,{mode: 'cors'})
      .then(response => response.json())
      .then(json =>
       this.setState({
            data: json.data,
            category_name_ar:json.category_name_ar,
            num_rows:json.num_rows,
            scrolling: false
        })
   );
 }

 componentWillMount() {
    this.loadUser();
 }
    

 Item_list(){

    const items = this.state.data || [] 
    if(this.state.num_rows>0){

                return(

                    items.map(data => (
                        <Link key={data.doctor_id} to={`/doctor/${data.doctor_slug}`}   className="dr-list-item-link">
                                <Row  className="dr-list-item">

                                <Col sm={4} xs={4} lg={4} >
                                <Image src={data.profile_image_url}  />

                                </Col>

                                <Col  sm={8} xs={8} lg={8} className="dr-list-item-desc">
                                <h4>{data.doctor_name}</h4>
                                <p>{data.category_name_ar}</p>
                                <p>{data.doctor_location}</p>
                               
                                </Col>   
                            </Row>
                        </Link>
                        ))
                )
    }
    else{
        return(

            <div className="doc-img-home">
            <img src={docimg} className="" />
            <h4>آسف! سيأتي الأطباء الجدد قريبًا هنا</h4>
            </div>  

            
        )
    }

 }

  render() {

  return (

     
  <div  className="page">
   
    <Navbar expand="lg" variant="light" bg="light" fixed="top">
    <Container>
      <Row>

            <Col className='text-right'>
            <Navbar.Brand href="#">
                <img src={logo} className="app-logo" />

            </Navbar.Brand>
            </Col>
            <Col>
                <Link to="/categories" className="back-link">عودة</Link>
            </Col>
      </Row>
    </Container>
    </Navbar>

   

                    <div className="full-bg-category-title">
                        <h4>{this.state.category_name_ar}</h4>
                    </div>
                
                    
                    <Container className="page-start">
                        
                    { this.Item_list() }
                            

        

                        
                




            </Container>

            </div>

  );
  }

}


export default Category;
