import React from 'react';
import {Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Footer() {
  return (
    <footer>
    <Container>
        <Row>
            <Col sm={12} className="text-center">
               <p>صنع في المغرب</p>
            </Col>







        </Row>
    </Container>
    </footer>   

  );
}

export default Footer;
