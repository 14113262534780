import React from 'react';
import {Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from 'react-bootstrap/Media';
import logo from '../components/img/logo-allo_tbib.svg';
import Navbar from 'react-bootstrap/Navbar';



console.log(logo)
function Header() {
  return (
    <div>
    <Navbar expand="lg" variant="light" bg="light" fixed="top">
    <Container>
      <Row>

            <Col>
            <Navbar.Brand href="#">
                <img src={logo} className="app-logo" />

            </Navbar.Brand>
            </Col>
            
      </Row>
    </Container>
    </Navbar>
  
    
    
    </div>
  );
}

export default Header;












