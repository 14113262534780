import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './components/Home';
import Category from './components/Category';
import Doctor from './components/Doctor';
import Categories from './components/Categories';
import Doctor_Registeration from './components/Doctor_Registeration'

import { 
  Route, 
  Switch, 
  BrowserRouter,
} from 'react-router-dom';
import {Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
const supportsHistory = 'pushState' in window.history;

const App = ({  }) => (
  <BrowserRouter forceRefresh={!supportsHistory}>

  <div>
 
  <main>
    <Route
      render={({ location }) => {
        const { pathname } = location;
        return (
          <TransitionGroup>
            <CSSTransition 
              key={pathname}
              classNames="page"
              timeout={{
                enter: 1000,
                exit: 0,
              }}
            >
              <Route
                location={location}
                render={() => (
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={Home}
                    />
                    <Route
                      path="/Category/:category_slug" component={Category}
                    />
                    <Route
                      path="/categories" component={Categories}
                    />
                    <Route
                      path="/doctor/:doctor_slug" component={Doctor}
                    />

                    <Route
                      path="/doctor-registration" component={Doctor_Registeration}
                    />


                  </Switch>
                )}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }}
    />
  </main>
</div>
</BrowserRouter>
);

export default App;
